<template>
  <div>
    <f-default-header :title="$t('pages.approvals.title')" :subtitle="$t('pages.approvals.subtitle')" />

    <f-list />
  </div>
</template>

<script>
import FList from "../../components/approvals/List.vue";

export default {
  components: {
    FList,
  },
  created() {
    this.list();
  },
  methods: {
    // create() {
    // 	this.$router.push({
    // 		name: 'order-create'
    // 	})
    // },
    async list() {
      this.$store.dispatch("system/loading", "data");
      await this.$store.dispatch("order/waitingApproval");
      this.$store.dispatch("system/loading", "done");
    },
  },
};
</script>
