<template>
	<f-card>
		<v-data-table
			:headers="headers"
			:items="orders">
			<template v-slot:[`item.action`]="{ item }" class="ap-btns-icons">
				<div
					:class="{ waiting: item.state == 0, loading: item.state == 1, approved: item.state == 2 }">
					<button class="btn-approval" @click="approve(item)" :class="{ locked: lockBtns }">
						<v-icon>mdi-check</v-icon>
					</button>
					<button class="btn-approval none" @click="approveNot(item)" :class="{ locked: lockBtns }">
						<v-icon>mdi-close</v-icon>
					</button>
				</div>
			</template>
		</v-data-table>
	</f-card>
</template>


<script>
export default {

	data() {
    return {
      lockBtns: false,
      dist: 'teste',
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('pages.general.distributor'),
          align: 'start',
          sortable: true,
          value: 'distributor',
        },
        {
          text: this.$t('pages.approvals.list.order'),
          align: 'start',
          sortable: true,
          value: 'number',
        },
        {
          text: this.$t('pages.approvals.list.funil'),
          align: 'start',
          sortable: true,
          value: 'funnel_number',
        },
        {
          text: this.$t('pages.approvals.list.createdAt'),
          align: 'end',
          sortable: true,
          value: 'created_at',
        },
        {
          text: this.$t('pages.approvals.list.action'),
          align: 'end',
          sortable: false,
          value: 'action',
        },
      ]
    }
  },
	computed: {
		orders() {
			return this.$store.state.order.list.map( item => {
				var status_message
				switch(item.status) {
					case 0:
						status_message = this.$t('pages.general.status.waitingProcess')
						break
					case 1:
						status_message = this.$t('pages.general.status.complete')
						break
					case 2:
						status_message = this.$t('pages.general.status.waitingCorrection')
						break
					case 3:
						status_message = this.$t('pages.general.status.cancel')
						break
					default:
						status_message = '-'
				}
				item.status_message = status_message
				item.action = true
				/**
				 * 0 - waiting for approval
				 * 1 - loading
				 * 2 - approved
				 */
				item.state = 0

				return item
			})
		},
		// loading() { return !this.$store.state.system.loading.done }
	},
	methods: {
		async approve( order ) {
			if( this.lockBtns ) return
			this.lockBtns = true
			var status = await this.$store.dispatch( 'order/approve', order.id )
			order.state = 2
			await this.$store.dispatch( 'order/waitingApproval' )
			this.lockBtns = false
		},
		async approveNot( order ) {
			if( this.lockBtns ) return
			this.lockBtns = true
			var status = await this.$store.dispatch( 'order/notApprove', order.id )
			order.state = 2
			await this.$store.dispatch( 'order/waitingApproval' )
			this.lockBtns = false
		},
	}
}
</script>

<style lang="scss">
.btn-approval {
	background-color: #006500;
    border-radius: 5px;
    padding: 5px 5px;
    display: inline-block;
    text-align: center;
	cursor: pointer;

	i {
		color: #fff !important;
	}

	&.none {
		margin-left: 10px;
		background-color: #be0000;
	}

	&.locked {
		background-color: #d9d9d9;
		i {
			color: #a3a3a3 !important;
		}
	}
}
</style>
